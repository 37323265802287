import React, {useEffect, useState} from 'react';
import { Menu,  Badge, Avatar, List, Button } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  CheckCircleOutlined,
  ShoppingCartOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import NotifictionService from "../../services/NotifictionService";
import {
  NovuProvider,
  PopoverNotificationCenter
} from "@novu/notification-center";

const getIcon =  icon => {
  switch (icon) {
    case 'Urgent: Confirmer la Commande de Plus de 1000dt':
      return <ShoppingCartOutlined />;
    case 'Notification : Passage en Super Grade':
      return <RocketOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}

const getNotificationBody = list => {
  function getName(subject) {
    switch (subject) {
      case "Urgent: Confirmer la Commande de Plus de 1000dt":
        return "Order Status"
      case "Notification : Passage en Super Grade":
        return "Passage Grade"
      default:
        return subject
    }
  }
    function getType(subject) {
      switch (subject) {
        case "Urgent: Confirmer la Commande de Plus de 1000dt":
          return "warning"
        case "Notification : Passage en Super Grade":
          return "primary"
        default:
          return 'info'
      }
  }

  return list.length > 0 ?
  <List
    size="small"
    itemLayout="horizontal"
    dataSource={list}
    renderItem={item => (
      <List.Item className="list-clickable">
        <Flex alignItems="center">
          <div className="pr-3">
             <Avatar className={`ant-avatar-${getType(item.subject)}`} icon={getIcon(item.subject)} />
          </div>
          <div className="mr-3">
            <span className="font-weight-bold text-dark">{item.name ? item.name : getName(item.subject)} </span>
            <span className="text-gray-light">{item.desc ? item.desc : item.subject}</span>
          </div>
        </Flex>
      </List.Item>
    )}
  />
  :
  <div className="empty-notification">
    <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
    <p className="mt-3">You have viewed all notifications</p>
  </div>;
}

export const NavNotification = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    NotifictionService.getAdminNotification()
        .then(r => {
          setData(r.data)
        })
  }, []);


  const clearNotifications = () => {
    NotifictionService.clearAdminNotifications()
        .then(r => {
          setData(r.data)
        })
  }

  /*const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button className="text-primary" type="link" onClick={clearNotifications} size="small">Clear </Button>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(data)}
      </div>
      {
        data.length > 0 ?
        <div className="nav-notification-footer">
          <a className="d-block" href="/app/notifications/all">View all</a>
        </div>
        :
        null
      }
    </div>
  );*/

  return (
      <NovuProvider
          subscriberId="66ec4d3ecf609b1343ad440f"
          //subscriberId="novu-in-app-GWuU9Ywh7D"
          //applicationIdentifier="eBb6iSIiIz4y"
          applicationIdentifier="qUtkLF9ZIi0B"
      >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <PopoverNotificationCenter colorScheme="dark" offset={35}>
            {({ unseenCount }) => (
                <Badge count={unseenCount}>
                  <BellOutlined className="nav-icon mx-auto" type="bell" />
                </Badge>
            )}
          </PopoverNotificationCenter>
        </Menu.Item>
      </Menu>
      </NovuProvider>
  )
}


export default NavNotification;
